import React, { useEffect, useState } from "react";
import HalvingItem from "./halving/HalvingItem";
import {
  btcImage,
  ltcImage,
  dashImage,
  BTC_HALVING_BLOCKS,
  LTC_HALVING_BLOCKS,
  DASH_REDUCTION_INTERVAL,
  BTC_AVERAGE_BLOCK_TIME,
  LTC_AVERAGE_BLOCK_TIME,
  DASH_AVERAGE_BLOCK_TIME,
  CACHE_TIMEOUT,
} from "./halving/constants";
import "../styles/Halving.css";

const Halving = ({ setHasSearch }) => {
  const [btcTimeTillHalving, setBtcTimeTillHalving] = useState(0);
  const [ltcTimeTillHalving, setLtcTimeTillHalving] = useState(0);
  const [dashTimeTillReduction, setDashTimeTillReduction] = useState(0);
  const [btcEta, setBtcEta] = useState("");
  const [ltcEta, setLtcEta] = useState("");
  const [dashEta, setDashEta] = useState("");

  useEffect(() => {
    setHasSearch(false);

    const fetchDataIfNeeded = (currency) => {
      const cachedData = localStorage.getItem(currency);
      if (cachedData) {
        const { timestamp, data } = JSON.parse(cachedData);
        if (Date.now() - timestamp < CACHE_TIMEOUT) {
          return data;
        }
      }
      return null;
    };

    const storeData = (currency, data) => {
      const cacheEntry = { timestamp: Date.now(), data };
      localStorage.setItem(currency, JSON.stringify(cacheEntry));
    };

    const fetchCurrentBlockHeight = async (
      currency,
      apiUrl,
      halvingBlocks,
      setTimeTillEvent,
      averageBlockTime,
      isDash = false
    ) => {
      const cachedData = fetchDataIfNeeded(currency);
      if (cachedData) {
        setTimeTillEvent(cachedData);
        return;
      }

      const response = await fetch(apiUrl);
      const data = await response.json();
      const currentBlockHeight = data.height;
      let timeTillEvent = 0;

      if (isDash) {
        const nextReductionBlock =
          currentBlockHeight +
          (DASH_REDUCTION_INTERVAL -
            (currentBlockHeight % DASH_REDUCTION_INTERVAL));
        timeTillEvent =
          (nextReductionBlock - currentBlockHeight) * averageBlockTime;
      } else {
        const nextHalvingBlock = halvingBlocks.find(
          (block) => block > currentBlockHeight
        );
        if (nextHalvingBlock) {
          timeTillEvent =
            (nextHalvingBlock - currentBlockHeight) * averageBlockTime;
        }
      }

      storeData(currency, timeTillEvent);
      setTimeTillEvent(timeTillEvent);
    };

    fetchCurrentBlockHeight(
      "BTC",
      "https://api.blockcypher.com/v1/btc/main",
      BTC_HALVING_BLOCKS,
      setBtcTimeTillHalving,
      BTC_AVERAGE_BLOCK_TIME
    );
    fetchCurrentBlockHeight(
      "LTC",
      "https://api.blockcypher.com/v1/ltc/main",
      LTC_HALVING_BLOCKS,
      setLtcTimeTillHalving,
      LTC_AVERAGE_BLOCK_TIME
    );
    fetchCurrentBlockHeight(
      "DASH",
      "https://api.blockcypher.com/v1/dash/main",
      [],
      setDashTimeTillReduction,
      DASH_AVERAGE_BLOCK_TIME,
      true
    );

    const interval = setInterval(() => {
      setBtcTimeTillHalving((prevTime) => Math.max(prevTime - 1, 0));
      setLtcTimeTillHalving((prevTime) => Math.max(prevTime - 1, 0));
      setDashTimeTillReduction((prevTime) => Math.max(prevTime - 1, 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [setHasSearch]);

  useEffect(() => {
    const updateEta = (timeTillEvent, setEta) => {
      const etaDate = new Date(Date.now() + timeTillEvent * 1000);
      const etaDay = etaDate.getUTCDate().toString().padStart(2, "0");
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const etaMonth = months[etaDate.getUTCMonth()];
      const etaYear = etaDate.getUTCFullYear();
      const etaHours = etaDate.getUTCHours().toString().padStart(2, "0");
      const etaMinutes = etaDate.getUTCMinutes().toString().padStart(2, "0");

      setEta(`${etaMonth} ${etaDay}, ${etaYear} ${etaHours}:${etaMinutes} UTC`);
    };

    updateEta(btcTimeTillHalving, setBtcEta);
    updateEta(ltcTimeTillHalving, setLtcEta);
    updateEta(dashTimeTillReduction, setDashEta);
  }, [btcTimeTillHalving, ltcTimeTillHalving, dashTimeTillReduction]);

  return (
    <div className="halving">
      <h2>Halvings and Reductions</h2>
      <div className="halving-container">
        <HalvingItem
          currencyName="Bitcoin"
          currencySymbol="BTC"
          imageSrc={btcImage}
          timeTillEvent={btcTimeTillHalving}
          eta={btcEta}
        />
        <HalvingItem
          currencyName="Litecoin"
          currencySymbol="LTC"
          imageSrc={ltcImage}
          timeTillEvent={ltcTimeTillHalving}
          eta={ltcEta}
        />
        <HalvingItem
          currencyName="Dash"
          currencySymbol="DASH"
          imageSrc={dashImage}
          timeTillEvent={dashTimeTillReduction}
          eta={dashEta}
        />
      </div>
    </div>
  );
};

export default Halving;
