const AirdropsData = [
  {
    project: "Starknet",
    airdropStatus: "Finished",
    logo: "/images/starknet.png",
    infoLink: "https://cryptoast.fr/starknet-comment-quelle-heure-claim-tokens-strk-suite-airdrop/",
  },
  {
    project: "Wormhole",
    airdropStatus: "Claim",
    logo: "/images/wormhole.png",
    infoLink: "https://cryptoast.fr/airdrop-wormhole-comment-verifier-eligibilite-recuperer-tokens-w/",
  },
  {
    project: "ZeroLend",
    airdropStatus: "Announced",
    logo: "/images/zerolend.png",
    infoLink: "https://cryptoast.fr/zerolend-comment-etre-eligible-possible-airdrop/",
  },
  {
    project: "Zora",
    airdropStatus: "Not Announced",
    logo: "/images/zora.png",
    infoLink: "https://cryptoast.fr/airdrop-zora-comment-optimiser-chances-eligibilite/",
  },
  {
    project: "DeBank",
    airdropStatus: "Not Announced",
    logo: "/images/debank.png",
    infoLink: "https://cryptoast.fr/airdrop-debank-comment-tenter-devenir-eligible-facilement/",
  },
  {
    project: "Optimism",
    airdropStatus: "Finished",
    logo: "/images/optimism.png",
    infoLink: "https://cryptoast.fr/airdrop-optimism-40-millions-distribues-etes-vous-eligible/",
  },
  {
    project: "Pryzm",
    airdropStatus: "Announced",
    logo: "/images/pryzm.png",
    infoLink: "https://cryptoast.fr/comment-tenter-etre-eligible-airdrop-pryzm-cosmos-guide-explicatif/",
  },
  {
    project: "LayerZero",
    airdropStatus: "Announced",
    logo: "/images/layerzero.png",
    infoLink: "https://cryptoast.fr/airdrop-layerzero-guide-complet-eligible-zro/",
  },
  {
    project: "Scroll ZKP",
    airdropStatus: "Not Announced",
    logo: "/images/scroll.png",
    infoLink: "https://cryptoast.fr/scroll-comment-rendre-eligible-airdrop-layer-2/",
  },
];

export default AirdropsData;
