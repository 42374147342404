import React, { useEffect, useState } from "react";
import { fetchCryptoDataWithCache } from "../utils/cryptoApiUtils";
import SearchBar from "./SearchBar";
import CryptoRow from "./listecrypto/CryptoRow";
import TableHeader from "./listecrypto/TableHeader";
import Skeleton from "./ui/skeleton";
import { ChevronUpIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import "../styles/ListeCrypto.css";

const ListeCrypto = ({ setHasSearch }) => {
  const [cryptoData, setCryptoData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "index",
    direction: "ascending",
  });

  useEffect(() => {
    setHasSearch(true);

    const init = async () => {
      const rawData = await fetchCryptoDataWithCache();
      const dataWithIndex = rawData.map((crypto, index) => ({
        ...crypto,
        index: index + 1,
      }));
      setCryptoData(dataWithIndex);
      setIsLoading(false);
    };

    init();

    return () => setHasSearch(false);
  }, [setHasSearch]);

  // Skeleton rows generator
  const skeletonRows = [...Array(100)].map((_, index) => (
    <tr key={`skeleton-row-${index}`}>
      <td>
        <Skeleton className="skel-rank" />
      </td>
      <td>
        <Skeleton className="skel-icon" />
      </td>
      <td>
        <Skeleton className="skel-name" />
      </td>
      <td>
        <Skeleton className="skel-ticker" />
      </td>
      <td>
        <Skeleton className="skel-price" />
      </td>
      <td>
        <Skeleton className="skel-24h" />
      </td>
      <td>
        <Skeleton className="skel-mcap" />
      </td>
      <td>
        <Skeleton className="skel-volume" />
      </td>
    </tr>
  ));

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? (
        <ChevronUpIcon />
      ) : (
        <ChevronDownIcon />
      );
    }
    return null;
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...cryptoData];
    if (searchTerm) {
      sortableData = sortableData.filter(
        (crypto) =>
          crypto.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          crypto.symbol.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (sortConfig !== null) {
      sortableData = sortableData.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Adjusting for sorting by percentage change
        if (sortConfig.key === "pricechange24h") {
          aValue = parseFloat(aValue.replace("%", ""));
          bValue = parseFloat(bValue.replace("%", ""));
        } else {
          if (!isNaN(parseFloat(aValue)) && !isNaN(parseFloat(bValue))) {
            aValue = parseFloat(aValue);
            bValue = parseFloat(bValue);
          } else if (typeof aValue === "string" && typeof bValue === "string") {
            aValue = aValue.toLowerCase();
            bValue = bValue.toLowerCase();
          }
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [cryptoData, searchTerm, sortConfig]);

  return (
    <div className="liste-crypto">
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <h2>Ranking</h2>
      <div className="table-container">
        <table>
          <TableHeader sortData={sortData} renderSortIcon={renderSortIcon} />
          <tbody>
            {isLoading
              ? skeletonRows
              : sortedData.map((crypto) => (
                  <CryptoRow key={crypto.name} crypto={crypto} />
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListeCrypto;
