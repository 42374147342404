import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import CryptoDataProvider from "./coin360/CryptoDataProvider";
import CryptoBox from "./coin360/CryptoBox";

const HeatMapCrypto = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const createTreemap = (cryptoData, dimensions) => {
    const root = d3
      .hierarchy({ children: cryptoData })
      .sum((d) => d.marketcap)
      .sort((a, b) => b.value - a.value);

    return d3.treemap().size([dimensions.width, dimensions.height]).padding(1)(
      root
    );
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  const calculateFontSize = (symbol, boxWidth, boxHeight) => {
    let fontSize = Math.min(boxWidth / 7, boxHeight / 4);
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${fontSize}px Inter`;
    let metrics = context.measureText(symbol);

    while (metrics.width > boxWidth || fontSize > boxHeight) {
      fontSize -= fontSize * 0.1;
      context.font = `${fontSize}px Inter`;
      metrics = context.measureText(symbol);
    }

    return Math.max(fontSize, 8);
  };

  const getMainContentDimensions = () => {
    const isMobile = window.innerWidth <= 768;
    const width = isMobile ? window.innerWidth : window.innerWidth * 0.8;
    const headerHeight = isMobile
      ? document.querySelector(".side-menu")?.offsetHeight || 0
      : 0;
    const height = window.innerHeight - headerHeight;
    return { width, height };
  };

  useEffect(() => {
    setDimensions(getMainContentDimensions());

    const handleResize = () => {
      setDimensions(getMainContentDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <CryptoDataProvider>
      {(cryptoData) => {
        const treemapData =
          cryptoData.length > 0 ? createTreemap(cryptoData, dimensions) : null;
        return (
          <div
            style={{
              position: "relative",
              width: `${dimensions.width}px`,
              height: `${dimensions.height}px`,
              overflow: "hidden",
            }}
          >
            {treemapData?.leaves().map((leaf) => (
              <CryptoBox
                key={leaf.data.id || leaf.data.symbol}
                leaf={leaf}
                formatCurrency={formatCurrency}
                calculateFontSize={calculateFontSize}
              />
            ))}
          </div>
        );
      }}
    </CryptoDataProvider>
  );
};

export default HeatMapCrypto;
