import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  HamburgerMenuIcon,
  MagnifyingGlassIcon,
  Cross2Icon,
  ShadowOuterIcon,
  MoonIcon,
  InfoCircledIcon,
  Half2Icon,
  Component2Icon,
  PaperPlaneIcon,
} from "@radix-ui/react-icons";
import logo from "../assets/images/logotype.png";
import "../styles/SideMenu.css";

const SideMenu = ({ hasSearch, toggleSearch, isSearchVisible }) => {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const sideMenuRef = useRef();
  const location = useLocation();

  const handleBurgerClick = () => {
    setShowSideMenu(!showSideMenu);
    if (isSearchVisible) {
      toggleSearch(); // Hide search bar when side menu is toggled
    }
  };

  const handleLinkClick = () => {
    setShowSideMenu(false); // Close side menu when a link is clicked
  };

  const handleSearchToggle = () => {
    if (showSideMenu) {
      setShowSideMenu(false); // Close side menu when search is toggled
    }
    toggleSearch();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
        setShowSideMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={`side-menu ${showSideMenu ? "show" : ""}`}
        ref={sideMenuRef}
      >
        <div className="logo-section desktop">
          <NavLink to="/" onClick={handleLinkClick}>
            <img src={logo} alt="Logo" />
          </NavLink>
        </div>

        <div className="mobile-header">
          <NavLink to="/" onClick={handleLinkClick}>
            <img src={logo} alt="Logo" className="logo" />
          </NavLink>
          {hasSearch && (
            <button onClick={handleSearchToggle} className="search-toggle">
              {isSearchVisible ? <Cross2Icon /> : <MagnifyingGlassIcon />}
            </button>
          )}
          <button onClick={handleBurgerClick} className="burger-menu">
            <HamburgerMenuIcon />
          </button>
        </div>

        <div className="menu-links">
          <NavLink
            to="/liste-crypto"
            className={`menu-link ${
              location.pathname === "/" || location.pathname === "/liste-crypto"
                ? "active"
                : ""
            }`}
            onClick={handleLinkClick}
          >
            <ShadowOuterIcon />
            Ranking
          </NavLink>
          <NavLink
            to="/airdrops"
            className="menu-link"
            onClick={handleLinkClick}
          >
            <MoonIcon />
            Airdrops
          </NavLink>
          <NavLink
            to="/coin360"
            className="menu-link"
            onClick={handleLinkClick}
          >
            <Component2Icon />
            Coin360
          </NavLink>
          <NavLink
            to="/halving"
            className="menu-link"
            onClick={handleLinkClick}
          >
            <Half2Icon />
            Halving
          </NavLink>
          <NavLink to="/bridge" className="menu-link" onClick={handleLinkClick}>
            <PaperPlaneIcon />
            Bridge
          </NavLink>
          <NavLink to="/about" className="menu-link" onClick={handleLinkClick}>
            <InfoCircledIcon />À propos
          </NavLink>
        </div>
      </div>

      {showSideMenu && (
        <div className="side-menu-backdrop" onClick={handleBurgerClick}></div>
      )}
    </>
  );
};

export default SideMenu;
