import React from "react";
import '../../styles/Halving.css';

const Countdown = ({ timeTillHalving }) => {
  const formatTimeSegment = (timeSegment) =>
    timeSegment.toString().padStart(2, "0");

  const days = formatTimeSegment(Math.floor(timeTillHalving / 60 / 60 / 24));
  const hours = formatTimeSegment(Math.floor((timeTillHalving / 60 / 60) % 24));
  const minutes = formatTimeSegment(Math.floor((timeTillHalving / 60) % 60));
  const seconds = formatTimeSegment(Math.floor(timeTillHalving % 60));

  return (
    <div className="countdown">
      <div className="time-segment">
        {days}
        <br />
        <span className="cddate">Days</span>
      </div>
      <span className="colon">:</span>
      <div className="time-segment">
        {hours}
        <br />
        <span className="cddate">Hours</span>
      </div>
      <span className="colon">:</span>
      <div className="time-segment">
        {minutes}
        <br />
        <span className="cddate">Minutes</span>
      </div>
      <span className="colon">:</span>
      <div className="time-segment">
        {seconds}
        <br />
        <span className="cddate">Seconds</span>
      </div>
    </div>
  );
};

export default Countdown;
