import React from "react";

const CryptoBox = ({ leaf, formatCurrency, calculateFontSize }) => {
  const symbol = leaf.data.symbol.toUpperCase();
  const formattedPrice = Number.isFinite(leaf.data.price)
    ? formatCurrency(leaf.data.price)
    : "N/A";
  const boxWidth = leaf.x1 - leaf.x0;
  const boxHeight = leaf.y1 - leaf.y0;
  const fontSize = calculateFontSize(symbol, boxWidth, boxHeight);
  const percentageFontSize = fontSize * 0.4;
  const isPriceHigher = leaf.data.pricechange24h > 0;
  const backgroundColor = isPriceHigher
    ? "var(--color-green)"
    : "var(--color-red)";
  const textColor = isPriceHigher ? "var(--background-dark)" : "white";

  return (
    <div
      key={leaf.data.id || symbol}
      style={{
        position: "absolute",
        top: `${leaf.y0}px`,
        left: `${leaf.x0}px`,
        width: `${boxWidth}px`,
        height: `${boxHeight}px`,
        backgroundColor,
        boxSizing: "border-box",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: `${fontSize}px`,
        color: textColor,
        textAlign: "center",
        borderRadius: ".1rem",
        fontFamily: "Inter",
      }}
    >
      <div style={{ lineHeight: 1 }}>
        <div
          style={{
            fontSize: `${fontSize}px`,
            fontWeight: 600,
            marginBottom: "2px",
          }}
        >
          {symbol}
        </div>
        <div
          style={{
            fontSize: `${fontSize}px`,
            fontWeight: 400,
            marginBottom: "2px",
          }}
        >
          {formattedPrice}
        </div>
        <div style={{ fontSize: `${percentageFontSize}px`, fontWeight: 200 }}>
          {`${Number(leaf.data.pricechange24h).toFixed(2)}%`}
        </div>
      </div>
    </div>
  );
};

export default CryptoBox;
