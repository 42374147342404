import React from "react";
import Countdown from "./Countdown";
import '../../styles/Halving.css';

const HalvingItem = ({
  currencyName,
  currencySymbol,
  imageSrc,
  timeTillEvent,
  eta,
}) => {
  return (
    <div className="halving-item">
      <div className="halving-section">
        <div className="halving-header">
          <img alt={currencyName} src={imageSrc} className="halving-logo" />
          <div className="halving-info">
            <h4>{currencyName}</h4>
            <p>{currencySymbol}</p>
          </div>
        </div>
        <p>Next Halving in:</p>
        <Countdown timeTillHalving={timeTillEvent} />
        <p>
          <span className="spaneta">ETA:</span> {eta}
        </p>
      </div>
    </div>
  );
};

export default HalvingItem;
