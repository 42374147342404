import { useEffect, useState } from "react";
import { fetchCryptoDataWithCache } from "../../utils/cryptoApiUtils";

const CryptoDataProvider = ({ children }) => {
  const [cryptoData, setCryptoData] = useState([]);

  useEffect(() => {
    const init = async () => {
      const rawData = await fetchCryptoDataWithCache();
      const sortedData = rawData
        .filter((crypto) => crypto.symbol && crypto.marketcap)
        .map((crypto) => ({
          ...crypto,
          marketcap: parseFloat(crypto.marketcap),
          price: parseFloat(crypto.price),
          pricechange24h: parseFloat(crypto.pricechange24h),
        }))
        .sort((a, b) => b.marketcap - a.marketcap)
        .slice(0, 100);

      setCryptoData(sortedData);
    };

    init();
  }, []);

  return children(cryptoData);
};

export default CryptoDataProvider;
