import React from "react";

const VolumeAndCapFormat = ({ value }) => {
  const formatVolumeAndCap = (value) => {
    const number = Number(value);
    if (isNaN(number)) {
      console.error(
        "formatVolumeAndCap: Provided value is not a number",
        value
      );
      return value;
    }
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(number);
  };

  return <>{formatVolumeAndCap(value)}</>;
};

export default VolumeAndCapFormat;
