import React from "react";

const PriceFormat = ({ value }) => {
  const formatPrice = (value) => {
    const number = Number(value);
    if (isNaN(number)) {
      console.error("formatPrice: Provided value is not a number", value);
      return value;
    }
    return number >= 1
      ? new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(number)
      : `$${number.toFixed(6).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, "$1")}`;
  };

  return <>{formatPrice(value)}</>;
};

export default PriceFormat;
