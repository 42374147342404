import React from "react";
import { CheckCircledIcon, CrossCircledIcon } from "@radix-ui/react-icons";

const AirdropRow = ({ airdrop }) => (
  <tr>
    <td>{airdrop.id}</td>
    <td>
      <img
        src={airdrop.logo}
        alt={airdrop.project}
        style={{ width: "2rem", height: "2rem" }}
      />
    </td>
    <td>{airdrop.project}</td>
    <td>{airdrop.airdropStatus}</td>
    <td>
      <a href={airdrop.infoLink} target="_blank" rel="noopener noreferrer">
        Info
      </a>
    </td>
    <td>
      {airdrop.farmable === "yes" ? (
        <CheckCircledIcon style={{ color: "var(--color-green)" }} />
      ) : (
        <CrossCircledIcon style={{ color: "var(--color-red)" }} />
      )}
    </td>
  </tr>
);

export default AirdropRow;
