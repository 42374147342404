import { Cross2Icon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import React from "react";
import "../styles/SearchBar.css";

const SearchBar = ({ searchTerm, setSearchTerm }) => {
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  return (
    <div className="search-bar">
      <div className="search-input-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleInputChange}
          style={{ paddingLeft: "30px" }}
        />
        <div className="search-icon-container">
          <MagnifyingGlassIcon className="search-icon" />
        </div>
        {searchTerm && (
          <button onClick={clearSearch} className="clear-search">
            <Cross2Icon />
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
