import React, { useEffect } from 'react';
import '../styles/About.css';
import decryptImage from '../assets/images/decrypt.png';

const About = ({ setHasSearch }) => {
  useEffect(() => {
    setHasSearch(false);
  }, [setHasSearch]);

  return (
    <div className="about">
      <h2>About Capybaria</h2>

      <div className="grid-container">
        <div className="grid-item">
          <div className="about-section">
            <h4>Our Mission</h4>
            <p>
              Capybaria is dedicated to providing crypto enthusiasts with the most comprehensive tools and insights. From a top 100 crypto list sorted by market cap to an innovative coin360 heatmap, our platform is tailored to empower users in the dynamic world of cryptocurrency.
            </p>
          </div>
        </div>

        <div className="grid-item">
          <div className="about-section">
            <h4>About Me</h4>
            <img alt="Decrypt" src={decryptImage} className="avatar" />
            <p>
              Hi, I'm Decrypt, the creator of Capybaria. With a passion for the crypto world, I've designed Capybaria to be a one-stop shop for all your crypto needs. Check out my work at <a href="https://decrypt.im/" className="custom-link">decrypt.im</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
