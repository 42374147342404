import React from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@radix-ui/react-icons";

const PercentageChangeFormat = ({ percentageChange }) => {
  const formatPercentageChange = (percentageChange) => {
    const change = parseFloat(percentageChange.replace("%", ""));
    if (isNaN(change)) {
      console.error(
        "formatPercentageChange: Provided value is not a number",
        percentageChange
      );
      return <span className="price-unchanged">N/A</span>;
    }
    return (
      <div className="price-change-container">
        {change > 0 ? (
          <span className="price-up">
            <ChevronUpIcon
              style={{ verticalAlign: "middle", color: "var(--color-green)" }}
            />
            {`${change.toFixed(2)}%`}
          </span>
        ) : (
          <span className="price-down">
            <ChevronDownIcon
              style={{ verticalAlign: "middle", color: "var(--color-red)" }}
            />
            {`${Math.abs(change).toFixed(2)}%`}
          </span>
        )}
      </div>
    );
  };

  return <>{formatPercentageChange(percentageChange)}</>;
};

export default PercentageChangeFormat;
