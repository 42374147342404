import React, { useEffect, useState } from "react";
import Skeleton from "./ui/skeleton";
import "../styles/Bridge.css";

// Global flag to indicate if the script has been loaded
window.isStargateWidgetLoaded = window.isStargateWidgetLoaded || false;

const Bridge = ({ setHasSearch }) => {
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(
    window.isStargateWidgetLoaded
  );

  useEffect(() => {
    setHasSearch(false);

    const loadScript = () => {
      if (!window.isStargateWidgetLoaded) {
        const script = document.createElement("script");
        script.src =
          "https://unpkg.com/@layerzerolabs/stargate-ui@latest/element.js";
        script.async = true;
        script.onload = () => {
          // Set both local and global flags to true upon successful script load
          setIsWidgetLoaded(true);
          window.isStargateWidgetLoaded = true;
        };
        document.body.appendChild(script);
      } else {
        // If the script is already loaded globally, set the local state to true
        setIsWidgetLoaded(true);
      }
    };

    loadScript();
  }, [setHasSearch]);

  return (
    <div className="bridge">
      <div className="bridge-text">
        <h3>Explore the Omnichain Universe with Stargate Bridge</h3>
        <p>
          We are thrilled to announce that our website now harnesses the power
          of Stargate, a cutting-edge LayerZero technology bridge, to elevate
          your DeFi experience. Stargate stands as a cornerstone in the realm of
          omnichain DeFi, offering a fully composable liquidity transport
          protocol that's reshaping the way we interact with digital assets. By
          integrating Stargate, we empower you to seamlessly transfer native
          assets across chains with instant guaranteed finality, tapping into a
          unified pool of liquidity that's part of an ever-expanding omnichain
          universe. With a Total Value Locked (TVL) of over $331 million,
          Stargate's robust infrastructure ensures that your transactions are
          not just borderless but also secure and efficient. Join us on this
          journey to the future of decentralized finance, where the
          possibilities are as vast as the cosmos itself.
        </p>
      </div>

      <div className="bridge-ui">
        {/* Conditional rendering based on isWidgetLoaded */}
        {!isWidgetLoaded ? (
          <div className="stargate">
            <Skeleton className="custom-skeleton" />
          </div>
        ) : (
          <div className="stargate">
            <stargate-widget
              theme="dark"
              feeCollector="0x15358C1bFD36D32a8B687b1f7EbC581f40aC6c3b"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Bridge;
