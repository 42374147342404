import axios from "axios";

const API_URL = "https://capybar.app/api/getCryptos";
const CACHE_KEY = "cryptoDataCache";
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

export const fetchCryptoDataWithCache = async () => {
  const cacheEntry = localStorage.getItem(CACHE_KEY);
  if (cacheEntry) {
    const { timestamp, data } = JSON.parse(cacheEntry);
    const isCacheValid = Date.now() - timestamp < CACHE_DURATION;
    if (isCacheValid) {
      return data;
    }
  }

  try {
    const response = await axios.get(API_URL);
    const data = response.data;
    const timestamp = Date.now();
    localStorage.setItem(CACHE_KEY, JSON.stringify({ timestamp, data }));
    return data;
  } catch (error) {
    console.error("Error fetching crypto data:", error);
    return [];
  }
};
