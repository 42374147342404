import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import SideMenu from './components/SideMenu';
import ListeCrypto from './components/ListeCrypto';
import Airdrops from './components/Airdrops';
import Coin360 from './components/Coin360';
import Halving from './components/Halving';
import Bridge from './components/Bridge';
import About from './components/About';

function App() {
  const [hasSearch, setHasSearch] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const updateMainContentStyle = () => {
    const isMobile = window.innerWidth <= 768;
    const header = document.querySelector('.side-menu');
    const mainContent = document.querySelector('.main-content');
    if (mainContent) {
      if (isMobile && header) {
        const headerHeight = header.offsetHeight;
        mainContent.style.paddingTop = `${headerHeight}px`; // Set the top padding to match the header height
        mainContent.style.height = `calc(100vh - ${headerHeight}px)`; // Adjust height
      } else {
        mainContent.style.paddingTop = 0; // Reset top padding for desktop
        mainContent.style.height = '100vh'; // Reset height for desktop
      }
    }
  };

  useEffect(() => {
    updateMainContentStyle(); // Update style on mount

    const handleResize = () => {
      updateMainContentStyle(); // Update style on resize
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  return (
    <Router>
      <div className={`App ${showSearch ? 'show-search' : ''}`}>
        <SideMenu 
          hasSearch={hasSearch} 
          toggleSearch={toggleSearch} 
          isSearchVisible={showSearch}
        />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<ListeCrypto setHasSearch={setHasSearch} />} />
            <Route path="/liste-crypto" element={<ListeCrypto setHasSearch={setHasSearch} />} />
            <Route path="/airdrops" element={<Airdrops setHasSearch={setHasSearch} />} />
            <Route path="/coin360" element={<Coin360 setHasSearch={setHasSearch} />} />
            <Route path="/halving" element={<Halving setHasSearch={setHasSearch} />} />
            <Route path="/bridge" element={<Bridge setHasSearch={setHasSearch} />} />
            <Route path="/about" element={<About setHasSearch={setHasSearch} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
