import React from "react";

const TableHeader = ({ sortData, renderSortIcon }) => {
  return (
    <thead>
      <tr>
        <th onClick={() => sortData("index")}># {renderSortIcon("index")}</th>
        <th></th>
        <th onClick={() => sortData("name")}>Name {renderSortIcon("name")}</th>
        <th onClick={() => sortData("symbol")}>
          Ticker {renderSortIcon("symbol")}
        </th>
        <th onClick={() => sortData("price")}>
          Price {renderSortIcon("price")}
        </th>
        <th onClick={() => sortData("pricechange24h")}>
          24h %{renderSortIcon("pricechange24h")}
        </th>
        <th onClick={() => sortData("marketcap")}>
          Market Cap {renderSortIcon("marketcap")}
        </th>
        <th onClick={() => sortData("volume24h")}>
          Volume 24h {renderSortIcon("volume24h")}
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
