import React from "react";
import PriceFormat from "./PriceFormat";
import VolumeAndCapFormat from "./VolumeAndCapFormat";
import PercentageChangeFormat from "./PercentageChangeFormat";

const CryptoRow = ({ crypto }) => {
  return (
    <tr>
      <td>{crypto.index}</td>
      <td>
        <img
          src={`https://capybaria.vercel.app${crypto.imageurl}`}
          alt={crypto.name}
          style={{ width: "2rem", height: "2rem" }}
        />
      </td>
      <td>{crypto.name}</td>
      <td>{crypto.symbol.toUpperCase()}</td>
      <td>
        <PriceFormat value={crypto.price} />
      </td>
      <td>
        <PercentageChangeFormat percentageChange={crypto.pricechange24h} />
      </td>
      <td>
        <VolumeAndCapFormat value={crypto.marketcap} />
      </td>
      <td>
        <VolumeAndCapFormat value={crypto.volume24h} />
      </td>
    </tr>
  );
};

export default CryptoRow;
