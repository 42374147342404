import React, { useEffect, useState } from "react";
import SearchBar from "./SearchBar";
import AirdropList from "./airdrops/AirdropList";
import AirdropsData from "./airdrops/AirdropsData";
import "../styles/Airdrops.css";

const Airdrop = ({ setHasSearch }) => {
  const [airdropData, setAirdropData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "farmable",
    direction: "descending",
  });

  useEffect(() => {
    setHasSearch(true);

    // Previously: enriching data and possibly assigning IDs here. Now: Just enrich.
    const enrichedData = AirdropsData.map((airdrop) => {
      // Directly assign 'farmable' based on 'airdropStatus'
      airdrop.farmable = ["Announced", "Not Announced"].includes(
        airdrop.airdropStatus
      )
        ? "yes"
        : "no";
      return airdrop;
    });

    setAirdropData(enrichedData);
    return () => setHasSearch(false);
  }, [setHasSearch]);

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...airdropData];

    if (searchTerm) {
      sortableData = sortableData.filter((airdrop) =>
        airdrop.project.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (typeof aValue === "string") {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    // Assign IDs based on the current order
    return sortableData.map((airdrop, index) => ({
      ...airdrop,
      id: index + 1,
    }));
  }, [airdropData, searchTerm, sortConfig]);

  return (
    <div className="airdrop">
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <h2>Airdrop List</h2>
      <AirdropList
        sortedData={sortedData}
        sortData={sortData}
        sortConfig={sortConfig}
      />
    </div>
  );
};

export default Airdrop;
