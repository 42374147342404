// Constants for the images, now pointing to the public folder
export const imageBasePath = process.env.PUBLIC_URL + "/images";
export const btcImage = `${imageBasePath}/bitcoin.png`;
export const ltcImage = `${imageBasePath}/litecoin.png`;
export const dashImage = `${imageBasePath}/dash.png`;

export const BTC_HALVING_BLOCKS = [630000, 840000, 1050000, 1260000];
export const LTC_HALVING_BLOCKS = [630000, 3360000, 4200000, 5040000];
export const DASH_REDUCTION_INTERVAL = 210240;
export const BTC_AVERAGE_BLOCK_TIME = 600;
export const LTC_AVERAGE_BLOCK_TIME = 150;
export const DASH_AVERAGE_BLOCK_TIME = 150;
export const CACHE_TIMEOUT = 3600000; // 1 hour in milliseconds
