import React from "react";
import AirdropRow from "./AirdropRow";
import SortIcon from "./SortIcon";

const AirdropList = ({ sortedData, sortData, sortConfig }) => (
  <div className="table-container">
    <table>
      <thead>
        <tr>
          <th>
            #
          </th>
          <th></th>
          <th onClick={() => sortData("project")}>
            Project <SortIcon sortConfig={sortConfig} sortKey="project" />
          </th>
          <th onClick={() => sortData("airdropStatus")}>
            Airdrop Status
            <SortIcon sortConfig={sortConfig} sortKey="airdropStatus" />
          </th>
          <th>Airdrop Info</th>
          <th onClick={() => sortData("farmable")}>
            Farmable <SortIcon sortConfig={sortConfig} sortKey="farmable" />
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedData.map((airdrop) => (
          <AirdropRow key={airdrop.id} airdrop={airdrop} />
        ))}
      </tbody>
    </table>
  </div>
);

export default AirdropList;
