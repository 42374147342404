import React from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@radix-ui/react-icons";

const SortIcon = ({ sortConfig, sortKey }) => {
  if (sortConfig.key === sortKey) {
    return sortConfig.direction === "ascending" ? (
      <ChevronUpIcon />
    ) : (
      <ChevronDownIcon />
    );
  }
  return null;
};

export default SortIcon;
